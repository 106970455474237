
// outsource dependencies
import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Alert } from 'react-bootstrap';
import { Field, reduxForm } from 'redux-form';

// local dependencies
import { PublicLogo } from '../../images';
import { APP } from '../../actions/types';
import Input from '../../components/input';
import ErrorMessage from '../../components/alert-error';
import CenteredBox from '../../components/centered-box';
import Preloader, { TYPE } from '../../components/preloader';
import { FORGOT_PASSWORD, PUBLIC_WELCOME } from '../../constants/routes';
import {config} from "../../constants";

/**
 * Sign In page
 *
 * @public
 */
export default connect(
    state => ({ hasMultiFactorAuth: state.app.hasMultiFactorAuth }),
    null
)( ({hasMultiFactorAuth}) => {
    return (<div id="publicPage">
        <CenteredBox>
            <div className="row top-indent-8 offset-bottom-10">
                <div className="col-xs-10 col-xs-offset-1 text-center">
                    <Link to={PUBLIC_WELCOME.LINK()}>
                        <PublicLogo />
                    </Link>
                </div>
            </div>
            <div className="row">
                <div className="col-xs-12">
                    <SignInForm />
                </div>
            </div>
            <div className="row">
                <div className="col-xs-6"> </div>
                <div className="col-xs-6 text-uppercase text-right">
                    <Link to={FORGOT_PASSWORD.LINK()} className={'link' + (hasMultiFactorAuth ? ' no-events' : '')}>
                        Forgot password
                    </Link>
                </div>
            </div>
        </CenteredBox>
    </div>);
});

/**
 * Sign In page
 *
 * @public
 */
const SignInForm = reduxForm({
    form: 'signInForm',
    /**
     * @param { Object } values - named properties of input data
     * @param { Object } meta - information about form status
     * @returns { Object } - named errors
     * @function validate
     * @public
     */
    validate: ( values, meta ) => {
        let errors = {};
        // EMAIL
        if ( !values.email ) {
            errors.email = 'Email is required';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,16}$/i.test(values.email)) {
            errors.email = 'Invalid email address';
        }
        // PASSWORD
        if ( !values.password ) {
            errors.password = 'Password is required';
        } else if ( values.password.length < 8 ) {
            errors.password = 'Password must contain at least 8 symbol character';
        }
        return errors;
    },
})( connect(
    state => ({ ...state.signIn, hasMultiFactorAuth: state.app.hasMultiFactorAuth }),
    dispatch => ({
        signIn: formData => dispatch({type: APP.SIGN_IN.REQUEST, ...formData}),
        signInMicrosoft: () => dispatch({type: APP.SIGN_IN.REQUEST, mode: 'microsoft'}),
        clearError: () => dispatch({type: APP.SIGN_IN.META, errorMessage: null}),
        hideNotification: () => dispatch({type: APP.SIGN_IN.META, notification: null}),
    })
)(({ invalid, handleSubmit, expectAnswer, signIn, errorMessage, clearError, notification, hasMultiFactorAuth, hideNotification, signInMicrosoft }) => (
    <form name="signInForm" onSubmit={ handleSubmit( signIn ) }>
        {/*MFA notification*/}
        {notification && (<div className="text-left offset-bottom-6">
            <Alert bsStyle="info" onDismiss={hideNotification}>{notification}</Alert>
        </div>)}
        <div className="offset-bottom-6">
            <Field
                type="mail"
                name="email"
                placeholder="Email"
                component={ Input }
                className="form-control input-lg"
                label={ <span className="h4"> Email </span> }
                disabled={ expectAnswer || hasMultiFactorAuth }
                    />
        </div>
        <div className="offset-bottom-8">
            <Field
                required
                name="password"
                type="password"
                placeholder="Password"
                component={ Input }
                className="form-control input-lg"
                disabled={ expectAnswer || hasMultiFactorAuth }
                label={ <span className="h4"> Password </span> }
                    />
        </div>
        {hasMultiFactorAuth && (<div className="offset-bottom-8">
            <Field
                skipTouch
                name="code"
                placeholder="Code"
                component={ Input }
                disabled={ expectAnswer}
                validate={ validateCode }
                className="form-control input-lg"
                label={ <span className="h4"> Code </span> }
                    />
        </div>)}

        <button type="submit" disabled={invalid||expectAnswer} className="btn btn-lg btn-block btn-primary offset-bottom-8">
            Login&nbsp;
            <Preloader type={TYPE.SPINNER} white expectAnswer={expectAnswer} />
        </button>

        {config.MICROSOFT_CLIENT_ID && (
            <button
                type="button"
                onClick={ signInMicrosoft }
                className="btn btn-lg btn-block btn-primary offset-bottom-4" >
                <i className="fa fa-windows" aria-hidden="true" />
                <span> Login with Microsoft </span>
                { expectAnswer&&(<i className="fa fa-spinner fa-spin fa-fw"> </i>) }
            </button>
        )}
        <ErrorMessage active title={'Error:'} message={errorMessage} onChange={clearError}/>
    </form>)
));

// NOTE can't put function directly in html (max update depth error)
const validateCode = value => !value ? 'Code is required' : undefined;
