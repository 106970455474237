
// outsource dependencies
import { call, put, takeEvery, select } from 'redux-saga/effects';

// local dependencies
import { historyPush } from '../../store';
import { APP } from '../../actions/types';
import { PRIVATE_WELCOME_SCREEN } from '../../constants/routes';
import {login, verifyMFA, MFA_ERROR, loginWithMicrosoft} from '../../services/api.service';
import msalInstance from "../../msal";

function* signInSaga ({mode, email, password, code}) {
    yield put({type: APP.SIGN_IN.META, errorMessage: null, notification: null });
    try {
        let { hasMultiFactorAuth, mfaToken } = yield select(state => state.app);
        let user = {};
        // NOTE MFA or common authorization flow
        if (!hasMultiFactorAuth) {
            if (mode === 'microsoft') {
                // NOTE microsoft authorization.
                const {idToken, username, accessToken} = yield call(getMicrosoftToken);
                user = yield call(loginWithMicrosoft, {idToken, username, accessToken, locale: 'en_US'});
            } else {
                user = yield call(login, {email, password});
            }
        } else {
            user = yield call(verifyMFA, {mfaToken, code});
            yield put({type: APP.META, hasMultiFactorAuth: false, mfaToken: null});
        }
        yield put({type: APP.SIGN_IN.SUCCESS, user});
        yield call(historyPush, PRIVATE_WELCOME_SCREEN.LINK());
    } catch ( e ) {
        // NOTE MFA required
        if (e.error === MFA_ERROR) {
            let notification = 'Security code has been sent to you. Please enter code and try again';
            yield put({type: APP.META, hasMultiFactorAuth: true, mfaToken: e.mfa_token});
            yield put({type: APP.SIGN_IN.ERROR, errorMessage: null, notification});
        } else {
            yield put({type: APP.SIGN_IN.ERROR, errorMessage: e.message});
        }
    }
    yield put({type: APP.SIGN_IN.FINISH});
}

/**
 * connect page sagas
 *
 *
 * @public
 */
export default function* () {
    yield takeEvery(APP.SIGN_IN.REQUEST, signInSaga);
}

/**
 * @description get Microsoft token
 * @example checkHealth().then( ... ).catch( ... )
 * @returns {Promise}
 * @public
 */
function getMicrosoftToken() {
    // See https://docs.microsoft.com/en-us/azure/active-directory/develop/tutorial-v2-javascript-spa
    // for any information on implementation
    // Add here scopes for id token to be used at MS Identity Platform endpoints.
    const loginRequest = {
        scopes: ["openid", "profile", "User.Read"]
    };
    // Add here scopes for access token to be used at MS Graph API endpoints.
    const tokenRequest = {
        scopes: ["openid", "profile", "User.Read"]
    };

    return msalInstance.loginPopup(loginRequest).then((data) => {
        let {idToken: {rawIdToken}, account: {userName}} = data;

        // Retrieving accessToken
        return (msalInstance.acquireTokenSilent(tokenRequest)
                .catch(error => {
                    console.error(error);

                    // fallback to interaction when silent call fails
                    return msalInstance.acquireTokenPopup(tokenRequest)
                        .then(tokenResponse => {
                            return tokenResponse;
                        }).catch(error => {
                            console.error(error);
                            return null;
                        });
                })
        ).then(accessTokenResponse => {
            let {accessToken} = accessTokenResponse;
            // TODO remove `idToken`, `username` since now flow based only on accessToken
            return {idToken: rawIdToken, username: userName, accessToken};
        });
    });
}
