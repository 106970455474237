// outsource dependencies
import {connect} from 'react-redux';
import {Col, Row} from 'react-bootstrap';
import {toastr} from 'react-redux-toastr';
import React, {Component, Fragment} from 'react';
import {ExpandMore, Lock, Settings} from '@material-ui/icons';
import {differenceBy, filter, get, reject, some, union} from 'lodash';
import {arrayPush, change, Field, FieldArray, getFormValues, reduxForm} from 'redux-form';
import {ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Paper, Typography} from '@material-ui/core';

// local dependencies
import {EDIT} from '../types';
import Page from '../../../components/page';
import MdInput from '../../../components/md-input';
import MdSwitch from '../../../components/md-switch';
import Alert from '../../../components/alert-error';
import {AddIconBtn, BackBtn, DangerBtn, DeleteIconBtn, ResetBtn, SubmitBtn} from '../../../components/md-button';
import MdDatePicker from '../../../components/md-date-picker';
import {ENTITY_TYPE, NEW_ID, TWO_FACTOR_AUTH_TYPES_MAP} from '../../../constants/spec';
import Preloader, {TYPE} from '../../../components/preloader';
import SelectEntities from '../../../components/select-entities';
import {BusinessUnitModel, OrganizationModel} from '../../../models';
import {MdAsyncSelect, MdSelect} from '../../../components/md-select';
import {USERS_MAP} from "../../../components/breadcrumbs/breadcrumbsMap";
import Breadcrumbs from "../../../components/breadcrumbs/breadcrumb";
import InputImage from "../../../components/input-image";

// config
export const FORM_NAME = 'userEditForm';
export const changeField = (field, value) => change(FORM_NAME, field, value);

export const idpIds = ['GOOGLE', 'MICROSOFT', 'OKTA'];

const VENDOR_ROLES = {
    VENDOR: 'VENDOR',
    VNDSUP: 'VNDSUP',
};
/**
 * check user has 'vendor' roles
 */
const hasVendorRoles = roles => some(roles, role => role.name === VENDOR_ROLES.VENDOR || role.name === VENDOR_ROLES.VNDSUP);

/**
 * normalize 'roles' field
 */
let normalizeRoles = (values, prevValues) => {
    let diff1 = differenceBy(values, prevValues, 'id');
    let diff2 = differenceBy(prevValues, values, 'id');
    let updated = union(diff1, diff2), newValues = values || prevValues;
    // NOTE remove other roles if 'vendor' roles has selected
    if (hasVendorRoles(updated)) {
        newValues = filter(newValues, role => role.name === VENDOR_ROLES.VENDOR || role.name === VENDOR_ROLES.VNDSUP);
    }
    // NOTE remove 'vendor' roles if 'common' roles has selected
    if (hasVendorRoles(values) && !hasVendorRoles(updated)) {
        newValues = reject(newValues, role => role.name === VENDOR_ROLES.VENDOR || role.name === VENDOR_ROLES.VNDSUP);
    }
    return newValues;
};

/**
 * Form wrapper
 *
 * @public
 */
export default connect(
    state => ({ ...state.users.edit }),
    dispatch => ({
        init: id => dispatch({type: EDIT.INITIALIZE, id}),
        clearError: () => dispatch({type: EDIT.META, errorMessage: null}),
    })
)(({ init, initialized, expectAnswer, errorMessage, clearError, match }) => {
    let isNew = match.params.id === NEW_ID;
    return (<Page init={()=>init(match.params.id)} initialized={initialized}>
        <Row className="top-indent-4 offset-bottom-2">
            <Col xs={12} md={8} mdOffset={2}>
                <Alert active message={errorMessage} onChange={clearError} />
            </Col>
        </Row>
        <Row className="offset-bottom-8">
            <Col xs={12} md={10} mdOffset={1}>
                <Paper className="indent-5">
                    <h2 className="offset-bottom-4">
                        {isNew ? 'Create' : 'Edit'}&nbsp;user&nbsp;
                        <Preloader type={TYPE.SPINNER} black style={{width: '25px'}} expectAnswer={expectAnswer} />
                    </h2>
                    <Breadcrumbs breadCrumbsMap={ USERS_MAP }  />
                    <ConnectedForm isNew={isNew} />
                </Paper>
            </Col>
        </Row>
    </Page>);
});

const ConnectedForm = connect(
    state => ({
        back: state.users.edit.back,
        initialValues: state.users.edit.data,
        disabled: state.users.edit.expectAnswer,
        formValues: getFormValues(FORM_NAME)(state),
        availableIdpIds: state.users.edit.availableIdpIds,
    }),
    dispatch => ({
        changePassword: () => dispatch({type: EDIT.CHANGE_PASSWORD}),
        update: formData => dispatch({type: EDIT.UPDATE, ...formData}),
        addIdpId: idpId => dispatch({type: EDIT.ADD_IDP_ID, idpId}),
        changeOrganization: organization => dispatch({type: EDIT.CHANGE_ORGANIZATION, organization}),
        resetPassword: () => toastr.confirm((<ConfirmResetPass />), { okText: 'Reset password', onOk: () => dispatch({type: EDIT.RESET_PASSWORD}) }),
        resetTOTP: () => toastr.confirm((<ConfirmResetTOTP />), { okText: 'Reset TOTP Application', onOk: () => dispatch({type: EDIT.RESET_TOTP}) }),
    })
)(reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
    /**
     * @param { Object } values - named properties of input data
     * @returns { Object } errors
     * @public
     */
    validate: values => {
        let errors = {};
        // email
        if ( !values.email ) {
            errors.email = 'Email is required';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,16}$/i.test(values.email)) {
            errors.email = 'Invalid email address';
        }
        // name
        if (!values.firstName) {
            errors.firstName = 'First name is required';
        }
        // role
        if (!values.roles || !values.roles.length ) {
            errors.roles = 'User roles is required. Must be selected at least one.';
        }
        // organization
        if (!values.organization) {
            errors.organization = 'Organization is required.';
        }
        // password
        if (values.passwordPlain && !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z0-9])(?=.{8,}$)/.test(values.passwordPlain)) {
            errors.passwordPlain = 'Invalid password.';
        }
        // confirm password
        if ( values.passwordPlain && values.confirmPassword !== values.passwordPlain ) {
            errors.confirmPassword = 'Passwords confirmation do not match with password';
        }
        // user idps
        if (values.idpUsers && values.idpUsers.length) {
            const idpArrayErrors = [];
            values.idpUsers.forEach((idp, idpIndex) => {
                const idpErrors = {};
                if (idp && idp.userIdentities && idp.userIdentities.length) {
                    const identitiesArrayErrors = [];
                    idp.userIdentities.forEach((identity, identityIndex) => {
                        const identityErrors = {};
                        if (!identity.userIdentity) {
                            identityErrors.userIdentity = 'User identity is required'
                        }
                        identitiesArrayErrors[identityIndex] = identityErrors;
                    });
                    if (identitiesArrayErrors.length) {
                        idpErrors.userIdentities = identitiesArrayErrors;
                        idpArrayErrors[idpIndex] = idpErrors;
                    }
                }
            });
            if (idpArrayErrors.length) {
                errors.idpUsers = idpArrayErrors;
            }
        }
        return errors;
    }
})(({pristine, invalid, disabled, reset, handleSubmit, update, isNew, resetPassword, resetTOTP, back, initialValues={}, formValues={}, changePassword, availableIdpIds, addIdpId }) => {
    return (
        <form autoComplete="off" noValidate name={FORM_NAME} onSubmit={handleSubmit(update)}>
            <Row className="offset-bottom-6">
                <Col sm={3} className="user-profile-logo">
                    <Row className="offset-top-8">
                        <Col>
                            <Field
                                required
                                name="profilePicture"
                                placeholder="Profile Picture"
                                url={formValues && formValues.profilePicture ? formValues.profilePicture.url : ""}
                                organization={formValues && formValues.organization ? formValues.organization : null}
                                component={InputImage}
                                crop = {{aspect: 1}}
                                className="form-control"
                                maxWeight={2}
                                processValue={(uploadResult) => {
                                    return uploadResult.logoDocument;
                                }}
                            />
                        </Col>
                    </Row>
                </Col>
                <Col sm={9}>
                    <Row>
                        <Col xs={12} md={6} className="offset-bottom-4">
                            <Field
                                name="firstName"
                                component={MdInput}
                                disabled={disabled}
                                placeholder={"First name"}
                                required={true}
                                label={(<strong className="required-asterisk"> First name </strong>)}
                            />
                        </Col>
                        <Col xs={12} md={6} className="offset-bottom-4">
                            <Field
                                name="lastName"
                                component={MdInput}
                                disabled={disabled}
                                placeholder={"Last name"}
                                required={true}
                                label={(<strong className="required-asterisk"> Last name </strong>)}
                            />
                        </Col>
                        <Col xs={12} className="offset-bottom-4">
                            <Field
                                name="email"
                                component={MdInput}
                                disabled={disabled}
                                placeholder={"Email"}
                                required={true}
                                label={(<strong className="required-asterisk"> Email </strong>)}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={6} className="offset-bottom-4">
                    <Field
                        type="tel"
                        name="corporatePhone"
                        component={MdInput}
                        disabled={disabled}
                        label={(<strong> Corporate phone </strong>)}
                    />
                </Col>
                <Col xs={12} md={6} className="offset-bottom-4">
                    <Field
                        type="tel"
                        name="mobilePhone"
                        component={MdInput}
                        disabled={disabled}
                        label={(<strong> Mobile phone </strong>)}
                    />
                </Col>
            </Row>
            <Row> <Col xs={12}> <ConnectedRolesAndWorkplace /> </Col> </Row>
            <Row className="offset-bottom-2">
                <Col xs={12}>
                    <Field
                        fullWidth={false}
                        component={MdSwitch}
                        name="changePassword"
                        label={(<strong> Change password </strong>)}
                        onClick={changePassword}
                    />
                </Col>
            </Row>
            {!formValues.changePassword ? '' :(<Row className="offset-bottom-4">
                <Col xs={12} md={6} className="offset-bottom-4">
                    <Field
                        type="password"
                        name="passwordPlain"
                        disabled={disabled}
                        component={MdInput}
                        label={(<strong> Password </strong>)}
                        helpText="Passwords must be at least 8 characters long and contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character"
                    />
                </Col>
                <Col xs={12} md={6} className="offset-bottom-4">
                    <Field
                        type="password"
                        disabled={disabled}
                        component={MdInput}
                        name="confirmPassword"
                        label={(<strong> Confirm Password </strong>)}
                    />
                </Col>
            </Row>)}
            <ExpansionPanel expanded={true} className="offset-bottom-4">
                <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                    <Settings color="error" />&nbsp;<Typography component="strong" color="error"> Settings </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <div style={{display: 'block', width: '100%'}}>
                        <Row>
                            <Col xs={12} lg={4} className="top-indent-1 offset-bottom-4">
                                <DangerBtn
                                    onClick={resetPassword}
                                    disabled={disabled||isNew}
                                    tooltip="Resetting user password - clear existing user sessions and send email with token to change password"
                                >
                                    Reset password&nbsp;
                                    <Preloader type={TYPE.SPINNER} expectAnswer={disabled} />
                                </DangerBtn>
                            </Col>
                            <Col xs={12} md={6} lg={4} className="offset-bottom-4">
                                <Field
                                    name="enabled"
                                    color="primary"
                                    fullWidth={false}
                                    disabled={disabled}
                                    component={MdSwitch}
                                    label={(<strong> Enable user </strong>)}
                                />
                            </Col>
                            <Col xs={12} md={6} lg={4} className="offset-bottom-4">
                                <Field
                                    name="locked"
                                    fullWidth={false}
                                    disabled={disabled}
                                    component={MdSwitch}
                                    label={(<strong> Lock user </strong>)}
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={12}>
                                <Paper className="indent-4 offset-bottom-4">
                                    <Row>
                                        <Col xs={8} className="offset-top-2">
                                            <Field
                                                fullWidth={false}
                                                disabled={disabled}
                                                component={MdSwitch}
                                                name="useMultiFactorAuth"
                                                label={(<strong> Multi-factor authentication </strong>)}
                                            />
                                            {(initialValues && initialValues.organization) && (
                                                <Field
                                                    fullWidth={false}
                                                    disabled={true}
                                                    component={MdSwitch}
                                                    name="useMultiFactorAuthForOrganization"
                                                    label={(<strong> ({(formValues && formValues.useMultiFactorAuthForOrganization ? 'ENABLED' : 'DISABLED')} for Organization) </strong>)}
                                                />
                                            )}
                                            {checkMultifactor(initialValues, formValues) && (
                                                <DangerBtn
                                                    onClick={resetTOTP}
                                                    className="offset-top-1"
                                                    disabled={disabled||isNew}
                                                    tooltip="Resetting user Multifactor - clear existing user sessions for TOTP"
                                                >
                                                    Reset TOTP
                                                    <Preloader type={TYPE.SPINNER} expectAnswer={disabled} />
                                                </DangerBtn>
                                            )}
                                        </Col>
                                        <Col xs={4}>
                                            {checkMultifactor(initialValues, formValues) && (
                                                <Field
                                                    labelKey="label"
                                                    valueKey="value"
                                                    component={MdSelect}
                                                    name="twoFactorType"
                                                    disabled={disabled}
                                                    isClearable={true}
                                                    label={(<strong> 2Factor Type (default TOTP) </strong>)}
                                                    options={Object.keys(TWO_FACTOR_AUTH_TYPES_MAP)}
                                                    simpleValue={(value) => {
                                                        return {value: value, label: TWO_FACTOR_AUTH_TYPES_MAP[value] ? TWO_FACTOR_AUTH_TYPES_MAP[value] : 'Choose 2Factor'}
                                                    }}
                                                    optionsLabel={({value}) => TWO_FACTOR_AUTH_TYPES_MAP[value]}
                                                />
                                            )}
                                        </Col>
                                    </Row>
                                </Paper>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={12} md={6} className="offset-bottom-4">
                                <Field
                                    disabled={disabled}
                                    name="expirationDate"
                                    component={MdDatePicker}
                                    label={(<strong> Expiration date </strong>)}
                                    helpText={(<code> Date when account will expire </code>)}
                                />
                            </Col>
                            <Col xs={12} md={6} className="offset-bottom-4">
                                <Field
                                    name="expired"
                                    color="secondary"
                                    fullWidth={false}
                                    disabled={disabled}
                                    component={MdSwitch}
                                    label={(<strong> Expired user </strong>)}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={6} className="offset-bottom-4">
                                <Field
                                    disabled={disabled}
                                    component={MdDatePicker}
                                    name="credentialsExpirationDate"
                                    label={(<strong> Credentials expiration date </strong>)}
                                    helpText={(<code> Date when credentials will expire </code>)}
                                />
                            </Col>
                            <Col xs={12} md={6} className="offset-bottom-4">
                                <Field
                                    color="secondary"
                                    fullWidth={false}
                                    disabled={disabled}
                                    component={MdSwitch}
                                    name="credentialsExpired"
                                    label={(<strong> Expired user credentials </strong>)}
                                />
                            </Col>
                        </Row>
                    </div>
                </ExpansionPanelDetails>
            </ExpansionPanel>
            {!isNew && (
                <ExpansionPanel className="offset-bottom-4">
                    <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                        <Lock color="error" />&nbsp;<Typography component="strong" color="error"> Third-party authorization </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <div style={{display: 'block', width: '100%'}}>
                            <Row className="offset-bottom-8">
                                <Col xs={12}>
                                    <div style={{display: 'flex', flexDirection: 'row', }}>
                                        <h4> Add one of the available types </h4>&nbsp;
                                        <div style={{width: 300}}>
                                            <Field
                                                name="idpIds"
                                                disabled={disabled}
                                                component={MdSelect}
                                                options={availableIdpIds}
                                                placeholder="Select idp type"
                                                onChange={(e, value) => addIdpId(value)}
                                            />
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={12}>
                                    <FieldArray name="idpUsers" component={IdpSettings} />
                                </Col>
                            </Row>
                        </div>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            )}
            <Row className="top-indent-2 offset-bottom-4">
                <Col xs={12} className="text-right">
                    <SubmitBtn isNew={isNew} disabled={disabled||pristine||invalid} />
                    &nbsp;&nbsp;&nbsp;
                    <ResetBtn disabled={disabled||pristine} onClick={reset} />
                    &nbsp;&nbsp;&nbsp;
                    <BackBtn disabled={disabled} back={back} />
                </Col>
            </Row>
        </form>
    );
}));

/**
 * prepared confirmation for reset password
 *
 * @public
 */
function ConfirmResetPass () {
    return (<div>
        <h3> Confirm reset password </h3>
        <p>
            Resetting user password - clear existing user sessions and send email to change password. Please note -
            <code> each </code> call of <code> reset password </code> do it again. And <code> invalidate previous </code> email.
        </p>
    </div>);
}

/**
 * prepared confirmation for reset 2Factor TOTP
 *
 * @public
 */
function ConfirmResetTOTP () {
    return (<div>
        <h3> Confirm reset TOTP Application Data </h3>
        <p>
            Resetting user 2Factor TOTP - clear existing user TOTP registration data. Please note -
            <code> User </code> will need <code> to remove </code> ValuRisQ record from his Authenticator Application and <code> SET </code> it upon next Login.
        </p>
    </div>);
}

class RolesAndWorkplace extends Component {
    constructor(props) {
        super(props);
        this.state = {
            vendors: [],
            businessUnits: [],
        };
        // NOTE fix react warnings
        let oss = this.setState.bind(this);
        this.setState = ( ...args ) => !this.isUnmauonted&&oss(...args);
    }

    componentDidMount() {
        let { formValues } = this.props;
        let organization = get(formValues, 'organization', null);
        if (organization) {
            this.getBusinessUnits(organization);
            this.getVendors({organization});
        }
    }

    changeRoles (roles) {
        if (hasVendorRoles(roles)) {
            this.getVendors({roles});
        } else {
            this.props.changeField('vendors', []);
        }
    }

    changeOrganization ( organization ) {
        this.props.changeField('businessUnit', null);
        this.props.changeField('vendors', []);
        this.getBusinessUnits(organization);
        this.getVendors({organization});
    }

    getBusinessUnits (organization) {
        BusinessUnitModel
            .getPage({ size: 6, filter: {organizationId: get(organization, 'id', null)} })
            .then(({items})=>{this.setState({businessUnits: items})})
            .catch(({message})=> this.props.showError(message));
    }

    getVendors ({organization, roles}) {
        let { formValues, showError } = this.props;
        roles = roles ? roles : get(formValues, 'roles', []);
        organization = organization ? organization : get(formValues, 'organization', null);
        hasVendorRoles(roles) && (
            OrganizationModel
                .getPage({size: 6, filter: {organizationType: 'Vendor', rootParent: organization}})
                .then(({items}) => this.setState({vendors: items}))
                .catch(({message}) => showError(message))
        )
    }

    // NOTE fix react warnings
    componentWillUnmount() { this.isUnmauonted = true; }

    render() {
        let { disabled, formValues } = this.props;
        let { businessUnits, vendors } = this.state;
        return (<Fragment>
            <Row className="offset-bottom-4">
                <Col xs={12}>
                    <SelectEntities
                        isMulti
                        name="roles"
                        disabled={disabled}
                        normalize={normalizeRoles}
                        type={ENTITY_TYPE.USER_ROLES}
                        onChange={(e, roles)=>this.changeRoles(roles)}
                        label={(<strong className="required-asterisk"> Roles </strong>)}
                            />
                </Col>
            </Row>
            <Row className="offset-bottom-4">
                <Col xs={12}>
                    <Field
                        name="title"
                        component={MdInput}
                        disabled={disabled}
                        label={(<strong> Title </strong>)}
                            />
                </Col>
            </Row>
            <Row>
                <Col xs={12} lg={6} className="offset-bottom-4">
                    <Field
                        disabled={disabled}
                        name="organization"
                        component={MdAsyncSelect}
                        onChange={e=>e&&this.changeOrganization(e)}
                        label={(<strong className="required-asterisk"> Organization </strong>)}
                        loadOptions={(name, done) => {
                            OrganizationModel.getListByName(name, 6, [0], 'Organization').then(done).catch(()=> done([]));
                        }}/>
                </Col>
                <Col xs={12} lg={6} className="offset-bottom-4">
                    <Field
                        name="businessUnit"
                        component={MdAsyncSelect}
                        defaultOptions={businessUnits}
                        label={(<strong> Business unit </strong>)}
                        getOptionLabel={item => item.fullLabel}
                        disabled={disabled || !get(formValues, 'organization', null)}
                        loadOptions={(name, done) => {
                            BusinessUnitModel
                                .getPage({size: 6, filter: { name, organizationId: get(formValues, 'organization.id', null) }})
                                .then(({items})=>done(items)).catch(()=> done([]));
                        }}/>
                </Col>
            </Row>
            {hasVendorRoles(get(formValues, 'roles', [])) && (<Row className="offset-bottom-4"><Col xs={12}>
                <Field
                    isMulti
                    name="vendors"
                    defaultOptions={vendors}
                    component={MdAsyncSelect}
                    label={(<strong> Vendors </strong>)}
                    disabled={disabled || !get(formValues, 'organization', null)}
                    loadOptions={(name, done) => {
                        OrganizationModel
                            .getPage({size: 6, filter: { name, organizationType: 'Vendor', rootParent: get(formValues, 'organization', null) }})
                            .then(({items})=>done(items)).catch(()=> done([]));
                    }}/>
            </Col> </Row>)}
        </Fragment>);
    }
}

const ConnectedRolesAndWorkplace = connect(
    state => ({
        disabled: state.users.edit.expectAnswer,
        formValues: getFormValues(FORM_NAME)(state),
    }),
    dispatch => ({
        showError: errorMessage => dispatch({type: EDIT.META, errorMessage}),
        changeField: (field, value) => dispatch(change(FORM_NAME, field, value)),
    })
)(RolesAndWorkplace);

const IdpSettings = connect(
    state => ({ disabled: state.users.edit.expectAnswer }),
    dispatch => ({
        add: (name, data) => dispatch(arrayPush(FORM_NAME, `${name}.userIdentities`, data)),
        removeIdpId: (idpId, index) => dispatch({type: EDIT.REMOVE_IDP_ID, idpId, index}),
    })
)(({fields, add, removeIdpId, disabled}) => {
    return fields.map((mKey, i) => {
        let item = fields.get(i);
        return (<div key={i}>
            <h3>
                <span>{get(item, 'idpId')}</span>
                <AddIconBtn disabled={disabled} onClick={() => add(mKey, {isNew: true, idpId: item.idpId})} />
                <DeleteIconBtn disabled={disabled} onClick={() => removeIdpId(item.idpId, i)} />
            </h3>
            <FieldArray name={`${mKey}.userIdentities`} component={Items} />
        </div>)
    })
});

const Items = connect(
    state => ({ disabled: state.users.edit.expectAnswer }),
    null
)( ({fields, disabled}) => {
    return fields.map((mKey, i) => {
        let item = fields.get(i);
        return (<div key={i} className="offset-bottom-2" style={{width: 400}}>
            <div style={{position: 'relative', paddingRight: 50}}>
                <Field
                    skipTouch
                    autoFocus
                    component={MdInput}
                    name={`${mKey}.userIdentity`}
                    disabled={!item.isNew || disabled}
                    label={(<strong className={item.isNew ? 'required-asterisk' : ''}> User Identity #{i+1} </strong>)}
                        />
            <div style={{position: 'absolute', top: 5, right: 0}}>
                <DeleteIconBtn disabled={disabled} onClick={() => fields.remove(i)}/>
            </div>
        </div></div>)
    });
});


function checkMultifactor(initialValues, currentUserInfo) {
    return (initialValues && initialValues.organization && initialValues.organization.useMultiFactorAuth) || (currentUserInfo && currentUserInfo.useMultiFactorAuth);
}
