
// outsource dependencies
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';

// local dependencies
import { PublicLogo } from '../../images';
import Input from '../../components/input';
import { PUBLIC } from '../../actions/types';
import CenteredBox from '../../components/centered-box';
import ErrorMessage from '../../components/alert-error';
import Preloader, { TYPE } from '../../components/preloader';
import { PUBLIC_WELCOME, SIGN_IN } from '../../constants/routes';
// configuration


/**
 *
 * @param {Object} props
 * @public
 */
function ForgotPasswordPage ({ invalid, handleSubmit, expectAnswer, forgotPassword, errorMessage, clearError }) {
    return (<div id="publicPage">
        <CenteredBox>
            <div className="row">
                <div className="col-xs-8 col-xs-offset-2 text-center">
                    <Link to={PUBLIC_WELCOME.LINK()}>
                        <PublicLogo />
                    </Link>
                </div>
            </div>
            <div className="row">
                <h1 className="col-xs-12 text-center">
                    <strong> Forgot password ? </strong>
                </h1>
            </div>
            <div className="row offset-bottom-4">
                <h3 className="col-xs-12 text-justify">
                     Please enter your <strong>email address</strong>
                     , and we'll send you a password reset email.
                </h3>
            </div>
            <div className="row">
                <div className="col-xs-12">
                    <form name="forgotPassword" onSubmit={ handleSubmit( forgotPassword ) }>
                        <div className="offset-bottom-8">
                            <Field
                                type="mail"
                                name="email"
                                placeholder="Email"
                                component={ Input }
                                disabled={ expectAnswer }
                                className="form-control input-lg"
                                    />
                        </div>
                        <button type="submit" disabled={invalid||expectAnswer} className="btn btn-lg btn-block btn-primary offset-bottom-8">
                            Reset&nbsp;password&nbsp;
                            <Preloader type={TYPE.SPINNER} white expectAnswer={expectAnswer} />
                        </button>
                        <ErrorMessage active title={'Error:'} message={errorMessage} onChange={clearError}/>
                    </form>
                </div>
            </div>
            <div className="row">
                <div className="col-xs-6 col-xs-offset-6 text-right text-uppercase">
                    <Link to={SIGN_IN.LINK()} className="link"> Sign In </Link>
                </div>
            </div>
        </CenteredBox>
    </div>);
}

export default reduxForm({
    form: 'forgotPassword',
    /**
     * @param { Object } values - named properties of input data
     * @param { Object } meta - information about form status
     * @returns { Object } - named errors
     * @function validate
     * @public
     */
    validate: ( values, meta ) => {
        let errors = {};
        // EMAIL
        if ( !values.email ) {
            errors.email = 'Email is required'
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,16}$/i.test(values.email)) {
            errors.email = 'Invalid email address'
        }
        return errors;
    },
})( connect(
    // mapStateToProps
    state => ({ ...state.forgotPassword }),
    // mapDispatchToProps
    dispatch => ({
        forgotPassword: ({email}) => dispatch({type: PUBLIC.FORGOT_PASSWORD.REQUEST, email }),
        clearError: () => dispatch({type: PUBLIC.FORGOT_PASSWORD.CLEAR}),
    })
)(ForgotPasswordPage) );
